<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <v-card class=" pb-5 my-5 mx-7" id="DownlineProduction">
        <v-col cols="12" class="q_green_1  pl-1 pt-3 pb-0">
            <div style="display:inline-block;">
                <p class="q-display-3 pl-5 py-2 white--text" id="q-downline-step-1">
                    Downline Stats
                </p>
            </div>
        </v-col>
        <v-row class="d-flex justify-start mx-5 pa-5" height="100%">
            <v-col cols="12" sm="4">
                <q-agency-tree id="q-downline-step-2" v-if="agent.AgentCode != 'SFG00001'" :drilldown="false" :agent="agent" type="baseshop" name="Baseshop" v-on:loaded="showAgent"></q-agency-tree>
                <v-divider class="ma-5" v-if="agent.AgentCode != 'SFG00001'"></v-divider>
                <q-agency-tree id="q-downline-step-3" :drilldown="true" :agent="agent" type="directdownline" name="Master Agency" v-on:loaded="showAgent"></q-agency-tree>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card class="pa-5" style="min-height:800px">
                    <v-row v-if="current_agent.AgentCode">
                        <v-col cols="12">
                            <h3>
                                {{ current_agent.AgentName }}
                            </h3>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <v-icon color="q_green_1">fas fa-envelope</v-icon>
                            <div class="overline" style="display:inline-block:">Email:</div>
                            <a :href="'mailto:' + current_agent.AgentEmail">{{ current_agent.AgentEmail }}</a>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <v-icon color="q_green_1">fas fa-user-secret</v-icon>
                            <div class="overline" style="display:inline-block:">Agent Code:</div>
                            <div style="display:inline-block:">{{ current_agent.AgentCode }}</div>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            <v-icon color="q_green_1">fas fa-crown</v-icon>
                            <div class="overline" style="display:inline-block:">Leadership Level:</div>
                            <div style="display:inline-block:"> {{ current_agent.LeadershipLevel }}</div>
                        </v-col>
                        <v-col cols="12">
                            <q-agent-stats :agent="current_agent" :minimal="true"></q-agent-stats>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-icon left>fas fa-arrow-circle-left</v-icon>
                        Choose an agent from the scrolling menus on the left
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import QAgencyTree from '@/components/lists/QAgencyTree.vue'
import QAgentStats from '@/components/stats/QAgentStats.vue';
export default {
    props: ['agent'],
    data: function() {
        return {
            current_agent: {},
        }
    },
    mounted: function() {},
    methods: {
        showAgent: function(agent) {
            if (agent.AgentCode != this.agent.AgentCode) {
                this.$set(this, 'current_agent', agent);
            }
        },
        loadAgentStats: function(AgentCode) {
            this.$router.push('/my_stats/agents/' + AgentCode);
        }
    },
    components: {
        QAgencyTree,
        QAgentStats
    }
}

</script>
